var opacity = 0.5;
var duration = 2000;
var cell_count = 0;

$(document).ready(function(){
    if ($('#world-map').length > 0) {
        $('.map-actions .rhombus').css({'opacity': 0})
        var waypoint = new Waypoint({
            element: document.getElementById('world-map'),
            handler: function (direction) {
                animateWorldMap();
                waypoint.disable();  // trigger only once
            },
            offset: 400
        });

        $('.toggle-map-layer').click(function () {
            var id = $(this).data('layer');
            if (id == '#gps') {
                $('#cell, #radio').fadeTo(300, 0, 'swing');
                $('#gps').fadeTo(300, 1, 'swing');

                $('#maparea-description').fadeOut(function() {
                    $(this).text($($('.map-actions .rhombus')[2]).data('text')).fadeIn();
                });
            }
            if (id == '#cell') {
                $('#gps, #radio').fadeTo(300, 0, 'swing');
                $('#cell').fadeTo(300, 1, 'swing');

                $('#maparea-description').fadeOut(function() {
                    $(this).text($($('.map-actions .rhombus')[1]).data('text')).fadeIn();
                });
            }
            if (id == '#radio') {
                $('#cell, #gps').fadeTo(300, 0, 'swing');
                $('#radio').fadeTo(300, 1, 'swing');

                $('#maparea-description').fadeOut(function() {
                    $(this).text($($('.map-actions .rhombus')[0]).data('text')).fadeIn();
                });
            }
        });
    }
});

function animateWorldMap() {
    var cell_ani_started = false
        gps_ani_started = false;

    $($('.map-actions .rhombus')[0]).fadeTo(500, 1);
    $('#maparea-description').fadeOut(function() {
        $(this).text($($('.map-actions .rhombus')[0]).data('text')).fadeIn();
    });

    $('#radio').children().fadeTo(duration, opacity, function () {
        if (cell_ani_started) {
            return;
        }
        cell_ani_started = true;
        setTimeout(500);
        $($('.map-actions .rhombus')[1]).fadeTo(500, 1);
        $('#maparea-description').fadeOut(function() {
            $(this).text($($('.map-actions .rhombus')[1]).data('text')).fadeIn();
        });

        randomFadeIn(function(){
            if (gps_ani_started) {
                return;
            }
            gps_ani_started = true;
            setTimeout(500);
            $($('.map-actions .rhombus')[2]).fadeTo(500, 1);
            $('#maparea-description').fadeOut(function() {
                $(this).text($($('.map-actions .rhombus')[2]).data('text')).fadeIn();
            });
            $('#gps').children().fadeTo(duration, opacity);
        });
    });
}

function toggleMapPart(id) {
    if ($(id).css('opacity') == 0) {
        $(id).fadeTo(300, 1, 'swing');
    } else if ($(id).css('opacity') == 1) {
        $(id).fadeTo(300, 0, 'swing');
    }
}


function randomFadeIn(after) {
    var elements = $('#cell').children();
    cell_count = elements.length;

    while (elements.length > 0) {
        var randomElements = [];
        for (var i = 0; i < 5; i++) {
            if (elements.length > 0) {
                var randomIndex = Math.floor(Math.random() * elements.length);
                randomElements[i] = $(elements.get(randomIndex));
                elements.splice(randomIndex, 1);
            }
        }

        fadeInAfterAnother(randomElements, after);
        setTimeout(Math.random()*100+100);
    }
}

function fadeInAfterAnother(elements, after) {
    var element = elements[0];
    elements.splice(0,1);

    $(element).fadeTo(500, opacity, 'swing',
        function(){
            cell_count--;
            if (cell_count == 0) {
                after();
            } else {
                fadeInAfterAnother(elements, after);
            }
    });
}
